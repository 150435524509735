
export const ProfessionalExperiencesDataStructure = [
    {
        company: "Creditas",
        position: "Sr Software Engineer",
        duration: "AUG 2022 - PRESENT",
        hardSkills: [
            { 'title': 'python', 'description': 'flask, pandas, pytest' },
            { 'title': 'javascript', 'description': 'react' },
            { 'title': 'terraform', 'description': '' },
            { 'title': 'aws', 'description': '' },
            { 'title': 'gcp', 'description': '' },
            { 'title': 'circleci', 'description': '' },
            { 'title': 'postgres', 'description': '' },
            { 'title': 'sqs', 'description': '' },
            { 'title': 'metabase', 'description': '' },
            { 'title': 'kibana', 'description': '' },
            { 'title': 'prometheus', 'description': '' }
        ],
        responsibilities: [
            "Stakeholders management to create an efficient backlog",
            "Integrations between People fintech systems with ETL processes",
            "Do automations to generate efficiency on People processes"
        ],
    },
    {
        company: "BTG Pactual",
        position: "Sr Software Engineer",
        duration: "JAN 2022 - AUG 2022",
        hardSkills: [
            { title: "python", description: "fastapi, pandas, pytest, pyspark" },
            { title: "javascript", description: "react" },
            { title: "AWS", description: "cloudformation, sqs, athena, glue, cloudwatch" },
            { title: "C#(.NET)", description: "" },
            { title: "azure devops", description: "" },
            { title: "jenkins", description: "" },
            { title: "postgres", description: "" },
            { title: "dynamo", description: "" },
            { title: "kafka", description: "" }
        ],
        responsibilities: [
            "Worked in a project at BTG Pactual, one of the biggest investment banks in LATAM, as a Software Engineer focused on Data Engineering",
            "Cross project to segment and map costs to help interested areas"
        ],
    },
    {
        company: "Avantia",
        position: "Software Developer",
        duration: "JAN 2021 - JAN 2022",
        hardSkills: [
            { 'title': 'python', 'description': 'opencv, tornado' },
            { 'title': 'typescript', 'description': 'angular' },
            { 'title': 'aws', 'description': '' },
            { 'title': 'azure devops', 'description': '' },
            { 'title': 'postgres', 'description': '' },
            { 'title': 'grey log', 'description': '' }
        ],
        responsibilities: [
            "Developed and maintained video monitoring systems to manage Artificial Intelligence services signals, focused on security"
        ],
    },
    {
        company: "Euromecantil",
        position: "Software Engineer",
        duration: "JUN 2019 - JAN 2021",
        hardSkills: [
            { title: "python", description: "pandas, selenium" },
            { title: "php", description: "laravel" },
            { title: "javascript", description: "vuejs" },
            { title: "digital ocean", description: "" },
            { title: "postgres", description: "" }
        ],
        responsibilities: [
            "Started as a salesman and finished as IT manager in this fintech startup",
            "Planned the architecture change from monolith to microservices",
            "Managed a 3 person team to build CRM, ERP, and auditing systems"
        ],
    },
    {
        company: "VTB",
        position: "Intern Software Developer",
        duration: "FEB 2019 - JUN 2019",
        hardSkills: [
            { 'title': 'C#', 'description': '.NET' },
            { 'title': 'Power BI', 'description': '' },
            { 'title': 'MongoDB', 'description': '' }
        ],
        responsibilities: [
            "Software Developer in software factory to build ERP and CRM for several clients"
        ],
    },
    {
        company: "BisaWeb",
        position: "Intern Software Developer",
        duration: "JUN 2017 - MAY 2018",
        hardSkills: [
            { 'title': 'PHP', 'description': 'Laravel, Zend3' },
            { 'title': 'MySQL', 'description': '' },
            { 'title': 'XAMPP', 'description': '' }
        ],
        responsibilities: [
            "Software Developer in a company that managed specific taxes from organizations"
        ],
    },
];

