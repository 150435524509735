
export const ScientificResearchesDataStructure = [
    {
        location: "Universidade Federal de Pernambuco",
        title: "Bot strategy to forecast cryptocoins values",
        duration: "JULY 2018 - JULY 2021",
        description: "It's a project that was born in a Natural Processing Language course. At first, the mission was to analyse sentiment on reddit  about specific cryptocoins. Than, me and my team mate had an insight to try to forecast those cryptocoin with the sentiments found. Later, the project became a full strategy, from architetural perspective, passing through production expected challenges and finishing with a proposed model that adapts do Concept Drifts. More can be found at https://www.cin.ufpe.br/~tg/2020-1/TG_CC/tg_mcbsf.pdf"
    }    
];

