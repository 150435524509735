
export const AcademicExperiencesDataStructure = [
    {
        school: "Bachelor's Degree at Universidade Federal de Pernambuco",
        course: "Computer Science",
        duration: "JULY 2013 - JULY 2020",
        description: "I hold a Bachelor's degree from the prestigious Center for Informatics (CIn) at the Federal University of Pernambuco (UFPE). My academic experience at CIn-UFPE equipped me with a comprehensive understanding of computer science principles, advanced programming techniques, and cutting-edge technologies. Through hands-on projects, team collaborations, and industry internships, I have developed strong problem-solving abilities, analytical thinking, and a passion for staying abreast of the latest advancements in the field. I am confident that my education and enthusiasm for technology make me a valuable asset for any challenging project or position in computer science."
    },
    {
        school: "MOOC at Udemy",
        course: " HashiCorp Certified: Terraform Associate 2023",
        duration: "JAN 2023 - MAY 2023",
        description: "Learned most of terraform functionalities, applying them in AWS as the course went through. Created ec2, iam_user, iam_policy, s3 bucket, bucket policy, elb, cloudfront, rds, security groups, etc. Also looked at non functional programming technicques in terraform, like data sensitive treats, scalabity, configuration files/commands, state lock, etc."
    },
    {
        school: "MOOC at StationX",
        course: "The Complete Cyber Security Course : Hackers Exposed!",
        duration: "FEB 2022 - FEB 2022",
        description: "An advanced practical skillset in defeating all online threats - advanced hackers, trackers, malware and all Internet nastiness including mitigating government spying and mass"
    },
    {
        school: "MOOC at University of Washington",
        course: "Machine Learning Foundations",
        duration: "JUN 2020 - AGO 2020",
        description: "I reviewed the basics of one of things that what I love in IT: machine learning. I got a deeper understanding of the core ways in which machine learning can improve a business"
    },
    {
        school: "MOOC at Hong Kong University",
        course: "Front-End Web UI Frameworks and Tools",
        duration: "MAR 2020 - MAR 2020",
        description: "Reviewed the basics of UI, passing through the core of html, css and javascript manipulations"
    }
    
];

