export const VolunteeringsDataStructure = [
    {
        title: 'OOP Monitor',
        description: 'Object Oriented Programing(OOP) lessons in Java to new students in my bachelors course, helping the teacher. Also designed and evaluated projects/exercises',
        githubUrl: 'https://www.github.com/mcbsf/portfolio-frontend',
        duration: 'FEB 2014 - FEB 2015',
        location: 'Universidade Federal de Pernambuco'
    }
    // Add more projects here
];
